import React, { useState, useCallback, useRef, useEffect } from 'react';
import { MdMoreHoriz, MdRemoveRedEye } from 'react-icons/md';

import { useHistory } from 'react-router-dom';
import DeliveryFeeDetails from '../DeliveryFeeDetails';

import {
  Container,
  FirstItem,
  LastItem,
  Badge,
  OptionsList,
  OptionsContainer,
} from './styles';

interface DeliveryFeeItemProps {
  item: {
    id: string;
    price: number;
    user: {
      name: string;
    };
    neighborhood: {
      name: string;
    };
    createdAt: string;
  };
}

const DeliveryFeeItem: React.FC<DeliveryFeeItemProps> = ({ item }) => {
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEvaluation, setOpenEvaluetion] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);
  const history = useHistory();

  const handleClickOutside = useCallback((event: any) => {
    if (ref.current && !ref.current.contains(event.target)) setVisible(false);
  }, []);

  const handleToggleVisible = useCallback(() => {
    setVisible(state => !state);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Container>
      <td>
        <FirstItem>
          <p>{item.user?.name}</p>
        </FirstItem>
      </td>
      <td>
        <div>
          <p>{item.neighborhood.name}</p>
        </div>
      </td>
      <td>
        <div>
          <p>{item.price}</p>
        </div>
      </td>
      <td>
        <LastItem>
          <OptionsContainer ref={ref}>
            <Badge onClick={() => handleToggleVisible()}>
              <MdMoreHoriz color="#C6C6C6" size={25} />
            </Badge>
            <OptionsList visible={visible}>
              <span />
            </OptionsList>
          </OptionsContainer>
        </LastItem>
      </td>
      <DeliveryFeeDetails
        open={open}
        setClose={() => setOpen(false)}
        item={item}
      />
    </Container>
  );
};

export default DeliveryFeeItem;
